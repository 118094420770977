.ql-editor {
  line-height: 1.8; /* 기본 줄 간 간격을 넓힘 */
  font-size: 18px;
}

.ql-editor img {
  display: block; /* 이미지를 블록 요소로 설정 (중앙 정렬) */
  margin: 20px auto; /* 위아래 여백 20px, 좌우는 자동 중앙 정렬 */
  max-width: 100%; /* 반응형 크기 조정 */
  height: auto;
}