.custom-file-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.input-file {
  position: absolute;
  height: 100px;
  opacity: 0;
  border: solid 1px;
  width: calc(50vw - 10px);
}

.custom-file-label {
  /* background-color: #3498db; */
  /* color: white; */
  padding: 8px 20px;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
}

.custom-file-label:hover {
  background-color: #2980b9;
}
