@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* // html 요소에 필요한 기본 스타일을 base 수준으로 레이어에 추가 */
@layer base {
  html,
  body,
  #root,
  .app {
    height: 100%;
    width: 100%;
    font-family: "Noto Sans KR", sans-serif;
    @apply text-neutral-700;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
